<template>
	<Alert :message="error" v-if="error" />

	<div class="center-flex" v-if="!companyName && !error">
		<page-loading />
	</div>

	<div class="center-flex" v-if="invitationStatus == 'Accepted'">
		<div>
			<p>This invitation has been accepted</p>
			<br />
			<router-link to="/checklists">
				<button class="btn btn-primary">Back to Risk Memo</button>
			</router-link>
		</div>
	</div>

	<div class="center-flex" v-if="error">
		<div class="logo">
			<img
				class="img-responsive"
				src="../../assets/rm-logo-blue.png"
				alt="logo"
			/>
		</div>
		<div>
			<h3>Opps! Something went wrong</h3>
			<p>Please try again or contact support for further assistance.</p>
		</div>
	</div>

	<div
		class="center-flex"
		v-if="companyName && invitationStatus == 'Pending'"
	>
		<div id="form-container">
			<div class="logo">
				<img
					class="img-responsive"
					src="../../assets/rm-logo-blue.png"
					alt="logo"
				/>
			</div>

			<form class="rm-form tile" @submit.prevent="handleSubmit">
				<div class="p-1rem">
					<div class="form-messages">
						<p>
							You have been granted third-party access to
							{{ companyName }}.
						</p>
						<p>
							To accept this invitation, click the button below.
							If this was sent in error, please ignore.
						</p>
					</div>
					<div class="form-submit">
						<div v-if="isLoading">
							<page-loading />
						</div>
						<button
							class="btn btn-primary"
							type="submit"
							value="submit"
							v-if="!isLoading"
						>
							Join {{ companyName }}
						</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import PageLoading from "../../components/PageLoading.vue";
import Alert from "../../components/Alert.vue";

import { timestamp } from "../../firebase/config";
import { onMounted, ref } from "@vue/runtime-core";

import { useRouter } from "vue-router";
import inspectorInviteCollection from "../../composables/inspectorInviteCollection";

export default {
	components: { PageLoading, Alert },
	name: "AcceptInspectorInvite",
	props: ["companyId", "id"],
	setup(props) {
		const { updateInviteData, getData, company, inspector, error } =
			inspectorInviteCollection();

		const companyName = ref("");
		const invitationStatus = ref("");

		const companyId = ref("");
		const router = useRouter();

		const isLoading = ref(false);

		onMounted(async () => {
			await getData(props.companyId, props.id);

			// getData error handling
			if (error.value) {
				return;
			}

			companyId.value = props.companyId;
			companyName.value = company.value.companyName;
			invitationStatus.value = inspector.value.status;
		});

		const handleSubmit = async () => {
			isLoading.value = true;
			var data = {
				companyId: companyId.value,
				companyName: companyName.value,
				locations: inspector.value.location,
				createdAt: timestamp(),
			};

			await updateInviteData(data, props.id, props.companyId);

			if (!error.value) {
				await getData(props.companyId, props.id);

				invitationStatus.value = inspector.value.status;
			}
			isLoading.value = false;
		};

		return {
			companyName,
			error,
			isLoading,
			handleSubmit,
			invitationStatus,
		};
	},
};
</script>

<style lang="scss" scoped>
.center-flex {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	height: 100vh;
}
.logo {
	width: 100%;
	padding: 2em 0;
	margin-top: -15vh;
	img {
		width: 180px;
	}
}
.message {
	padding: 0 2em 2em;
}
.form-input {
	position: relative;
}
.email-confirmed {
	position: absolute;
	top: 28px;
	right: 0;
	color: $rm-success;
	font-size: 1.5em;
}
</style>
