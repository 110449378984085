<template>
	<Alert :message="error" v-if="error" />
	<div class="modal">
		<div class="modal-container">
			<div class="modal-content tile">
				<form class="rm-form" @submit.prevent="handleSubmit">
					<div class="modal-header">
						<div class="left">
							<h3>Grant Third-Party Access</h3>
						</div>

						<div class="right">
							<router-link
								class="close"
								:to="{ name: 'InspectorInvitationsDash' }"
							>
								<fa icon="times" />
							</router-link>
						</div>
					</div>
					<div class="modal-body">
						<div class="form-warning">
							<b>Warning:</b>
							<p>
								This will allow the Third-Party to create
								checklists using your company data, including
								Employee data, edit Equipment data and assign
								actions.
							</p>
						</div>
						<div class="spacer-40"></div>
						<div class="form-input">
							<label>Location Access:</label>
							<vue-select
								v-model="selectedLocations"
								:options="locations"
								label-by="locationName"
								placeholder="Select locations"
								multiple
								searchable
								search-placeholder="Search & add locations"
							></vue-select>
						</div>

						<div class="spacer-20"></div>

						<div class="form-input">
							<label>Inspector Email address:</label>
							<input type="email" required v-model="email" />
							<div
								class="error-text"
								v-if="duplicateEmail && !success && !isLoading"
							>
								{{ emailExists }}
							</div>
							<div
								class="error-text"
								v-if="emailNotExist && !success && !isLoading"
							>
								{{ emailNotExists }}
							</div>
						</div>

						<div class="form-submit">
							<page-loading v-if="isLoading" />
							<button
								class="btn btn-primary"
								v-else
								:disabled="
									duplicateEmail ||
									emailNotExist ||
									!selectedLocations.length
								"
							>
								Send Invitation
							</button>
						</div>
						<div class="form-messages" v-if="error">
							{{ error }}
						</div>
						<div class="form-messages" v-if="success">
							Invitation sent!
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import { projectFirestore, timestamp } from "../../firebase/config";
import { computed, onMounted, watchEffect } from "@vue/runtime-core";
// import useCollection from "../../composables/useCollection";
import account from "../../composables/account";
import getDocument from "../../composables/getDocument";
import getDocumentOnce from "../../composables/getDocumentOnce";
import getCollection from "../../composables/getCollection";
import PageLoading from "../../components/PageLoading.vue";
// import LinkLocation from "../../components/checklists/TemplateBuilder/LinkLocation.vue";
import Alert from "../../components/Alert.vue";

export default {
	components: { PageLoading, Alert },
	name: "InviteInspectorModal",
	setup() {
		const { userDoc, companyId, user } = account();
		const router = useRouter();
		const error = ref(null);
		const isLoading = ref(false);
		const locations = ref([]);
		const selectedLocations = ref([]);
		const invitations = ref([]);
		const users = ref([]);
		const success = ref(false);
		const userObj = ref();

		let db = projectFirestore;

		// form refs
		const email = ref(null);
		const emailExists = ref("");
		const emailNotExists = ref("");

		const companyRef = db.collection("companies").doc(companyId);

		const userRef = db.collection("users");
		const { documents } = getCollection(userRef);

		const inspectorRef = db
			.collection("companies")
			.doc(companyId)
			.collection("inspector");
		const { documents: inspectordocuments } = getCollection(inspectorRef);

		onMounted(async () => {
			const { document } = await getDocumentOnce(companyRef);
			locations.value = document.value.locations;
		});

		const disable = computed(() => {
			if (userDoc.value) {
				return (
					userDoc.value.role == "Admin" ||
					userDoc.value.manages.length > 0
				);
			}
		});

		const duplicateEmail = computed(() => {
			if (inspectordocuments.value) {
				console.log("ss");
				invitations.value = inspectordocuments.value;

				let emails = [];

				invitations.value.forEach((invitation) => {
					emails.push(invitation.email);
				});

				if (!emails.includes(email.value)) {
					emailExists.value = null;
					return false;
				} else {
					emailExists.value = "This email has an existing invitation";
					return true;
				}
			}
		});

		const emailNotExist = computed(() => {
			if (documents.value) {
				users.value = documents.value;

				let emails = [];

				users.value.forEach((user) => {
					emails.push(user.email);
					if (user.email == email.value) {
						userObj.value = user;
					}
				});

				if (emails.includes(email.value)) {
					emailNotExists.value = null;
					return false;
				} else {
					emailNotExists.value =
						"Email must have a Risk Memo account";
					return true;
				}
			}
		});

		// const hasLocations = ref(false);

		// const updateLocation = (value) => {
		//     tempLocations.value = value;
		//     console.log(tempLocations.value.value);
		//     if (tempLocations.value.value.length) {
		//         hasLocations.value = true;
		//     } else {
		//         hasLocations.value = false;
		//     }
		// };

		// watchEffect(() => {
		//     if (tempLocations.value && tempLocations.value.length > 0) {
		//         hasLocations.value = true;
		//     } else {
		//         hasLocations.value = false;
		//     }
		// });

		const handleSubmit = async () => {
			error.value = null;
			isLoading.value = true;

			console.log(userObj.value);

			if (selectedLocations.value.length < 1) {
				error.value = "Please select a location";
				isLoading.value = false;
				return;
			}

			if (emailNotExists.value != null) {
				error.value = "The Inspector must have a Risk Memo account";
				isLoading.value = false;
				return;
			}

			const data = {
				id: userObj.value.id,
				companyId: userObj.value.companyId,
				email: userObj.value.email,
				fullName: userObj.value.fullName,
				status: "Pending",
				createdAt: timestamp(),

				location: selectedLocations.value,
			};

			try {
				await projectFirestore
					.collection("companies")
					.doc(companyId)
					.collection("inspector")
					.doc(userObj.value.id)
					.set(data);

				isLoading.value = false;
				success.value = true;
				setTimeout(() => {
					router.push({ name: "InspectorInvitationsDash" });
				}, 1000);
			} catch (e) {
				isLoading.value = false;
				error.value = "Could not create invitation";
			}
			isLoading.value = false;
		};

		return {
			error,
			handleSubmit,
			email,
			isLoading,
			disable,
			selectedLocations,
			locations,
			duplicateEmail,
			emailNotExist,
			emailExists,
			emailNotExists,
			success,
		};
	},
};
</script>

<style lang="scss" scoped>
.form-warning {
	margin: 0 auto;
	text-align: center;
	max-width: 40vw;
}
</style>
